
.recent-booking-container{
    background: #fff;
    display: flex;
    justify-content: space-between;
    /* border: 1px solid red; */
    align-items: baseline;
    margin: 40px;
    padding: 10px;
    border-radius: 4px;
}

.recent-booking-main h4{
  font-size: 22px;
  font-weight: 500;
}

.recent-booking-main p{
   font-size: 16px;
   font-weight: 500;
   color: #656060;
}

.recent-booking-btn button{
   font-size: 15px;
   background-color: #fff;
   padding: 15px 45px;
   border-radius: 4px;
   border: 1px solid #dfd2d2;
}