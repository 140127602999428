.select-main-container{
    display: flex;
    padding: 40px;
}





.select-main-container button{
    
}