.sidebar-main-container {
  /* width: 20%;
    position: fixed;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */

  align-self: flex-start;
  border-right: 1px solid rgba(203, 213, 225, 0.5);
  flex: 1 1;
  position: -webkit-sticky;
  position: sticky;
  top: 84px;
}

.sidebar-main-container p{
  font-size: 16px;
  padding: 12px;
  background-color: #fff;
  /* border: 1px solid #f0ecec; */
  border-bottom: 1px solid #f0ecec;
  text-align: justify;
  color: #656060;
  display: flex;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}



.sidebar-main-container p span{
  padding:0 10px;
}
.sidebar-main-container a{
  text-decoration: none;
}

