.nav-corporate-container {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: sticky;
  top: 0;
  z-index: 999;
  background: white;
}

.nav-corporate {
  display: flex;
  justify-content: space-between;
  /* width: 95%; */
  /* margin: auto; */
}
.corporate-booking p {
  margin-top: 16px;
  font-size: 18px;
  color: rgb(76, 74, 74);
}

.left-nav-corporate {
  display: flex;
  justify-content: space-evenly;
}

.nav-phone-num,
.nav-mail-id,
.nav-login {
  border-left: 1px solid rgb(217, 210, 210);
  padding: 26px;
  display: flex;
  align-content: center;
}

.left-nav-corporate p {
  margin: 3px 15px 0px 15px;
  /* border: 2px solid red; */
  color: rgb(76, 74, 74);
}

.nav-corporate-logo {
  display: flex;
  padding: 14px;
}

.nav-corporate-logo img {
  width: 110px;
  height: 40px;
  margin-top: 10px;
  margin-right: 30px;
  /* border: 2px solid red; */
}

@media screen and (max-width: 850px) {
  .corporate-booking,
  .nav-mail-id {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .corporate-booking,
  .nav-mail-id,
  .nav-phone-num,
  .nav-login {
    display: none;
  }

  .nav-corporate {
    justify-content: center;
  }

  .nav-corporate-logo {
    padding: 5px;
  }
}
