
.table-main-container{
    padding: 40px;
  
}

.table-main-container table{
  /* padding-right: 20px; */
  width: 100%;
  border: 1px solid rgb(228, 220, 220);
 
  
}

.table-main-container th{
    color: rgb(44, 42, 42);
    padding: 6px;
}