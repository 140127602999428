.img-three-main {
  display: flex;
  justify-content: space-between;
  margin-top: 70px;
  margin-bottom: 30px;
}
.img-three-main p {
  font-size: 22px;
  font-weight: 600;
  margin-top: 10px;
}
.img-three-main img {
  /* width: 150; */
}

.innovative-first {
  width: 70%;
  margin: auto;
}
.innovative-first span {
  font-size: 34px;
  font-weight: 600;
}
.innovative-first h2 {
  font-size: 28px;
  line-height: 36px;
  margin-top: 60px;
  /* font-weight: 600; */
}

.middle-content-big-img .big-img-main {
    width: 600px;
  }
.middle-three{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    width: 90%;
    margin: auto;
    margin-top: 110px;
    
    
}
.content-text{
    text-align: justify;
    width: 90%;
    margin: auto;
    padding: 50px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.content-text-second{
    display: flex;
    
}
.content-text h1{
    margin: 20px 0 60px 0;
}
.content-text p{
    font-size: 14px;
    line-height: 18px;
    margin-top: 10px;
    margin-bottom: 30px;
}

.icon-text-img{
    width: 60px;
    padding-right: 15px;
    height: 40px;
}


/* first-paragraph... */
.para-main-container{
  text-align: justify;
  margin-left:100px;
}

.para-main-container h1{
margin-bottom: 60px;
}

.button-h3-div{
display: flex;
justify-content: left;
text-align: justify;
}

.first-para button{
  background: #000;
  color: #fff;
  height: 25px;
  margin-top: 20px;
}

 .first-para h3{
  font-size: 22px;
  font-weight: 300;
  margin: 20px 0 10px 10px;
 }

.first-para p{
  font-size: 15px;
  width: 80%;
  /* margin: auto; */
  color: #484646;
  text-align: justify;
}


@media screen and (max-width: 850px) {

  .innovative-first {
    width: 80%;
}

.middle-three {
  display: grid;
  grid-template-columns: repeat(1,1fr);
  width: 100%;
  margin: auto;
  margin-top: 60px;
}

.middle-content-big-img .big-img-main {
  width: 100%;
}

}

@media screen and (max-width: 450px) {

  .innovative-first {
    width: 95%;
}

  .innovative-first h2 {
    font-size: 20px;
    line-height: 27px;
    margin-top: 40px;
}

  .img-three-main {
    display: flex;
   flex-direction: column;
    margin-top: 40px;
    margin-bottom: 30px;
}

.middle-three {
  display: grid;
  grid-template-columns: repeat(1,1fr);
  width: 100%;
  margin: auto;
  margin-top: 60px;
}

.middle-content-big-img .big-img-main {
  width: 100%;
}

.content-text {
  text-align: justify;
  width: 100%;
  margin: auto;
  padding: 18px;
}

.content-text h1 {
  margin: 10px 0 30px 0;
}

.first-para h3 {
  font-size: 14px;
  font-weight: 300;
  margin: 25px 0 5px 8px;
}

.para-main-container {
  text-align: justify;
  margin-left: 18px;
}

.para-main-container h1 {
  margin-bottom: 10px;
}
}