.bookings-main-container{
    display: flex;
    justify-content: space-between;
    padding: 40px;
    background: #f8f8fb;
}

.bookings-main-container .para{
    font-size: 22px;
    font-weight: 600;
    margin: 0;
}
.bookings-main-container input{
    border: none;
    font-size: 16px;
    padding: 14px 40px;
    width: 100%;
    outline: none;
    /* color: #fff; */
    border-radius: 25px;
    background: #fff;
}

.bookings-main-container button{
    padding: 15px 35px;
    letter-spacing: 1px;
    border-radius: 4px;
    border: none;
    color: white;
    background: #4644b1;
    font-size: 16px;
    height: fit-content;
}