.homepage-nav-container{
    /* border: 1px solid red; */
    /* height: 90px; */
    position: sticky;
    top: 0;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    z-index: 999;
    background: #fff;
    /* border-bottom: 1px solid #f0ecec; */

}
.homepage-nav-main{
    display: flex;
    /* position: fixed; */
    width: 95%;
    /* margin: auto; */
    justify-content: space-around;
   padding: 15px;
   /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  
    
}

.homepage-nav-logo img{
    height: 40px;
    width: 130px;
    margin-top: 5px;
    
}

.nav-all-content{
    display: flex;
}

.nav-input-search input{
    width: 300px;
    height: 48px;
    outline: none;
    font-size: 16px;
    /* padding: 10px; */
    border-radius: 4px 0 0 4px;
    border: 1px solid rgb(192, 188, 188);
}

.nav-calender{
 /* border: 1px solid red; */
 border: 1px solid rgb(192, 188, 188);
 /* margin: 10px; */
 font-size: 16px;
 padding: 0 30px;
}

.calendarWrap p{
    margin: 0;
    padding: 0;
}

.rdrDateRangePickerWrapper {
    display: inline-flex;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    position: absolute;
    top: 100%;
    left: 17%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.nav-room-guest{
    font-size: 16px;
    padding: 12px;
    border: 1px solid rgb(192, 188, 188);
}

.nav-booking{
    font-size: 16px;
    padding: 12px;
    border: 1px solid rgb(192, 188, 188);
}

.nav-search-btn button{
    padding: 17px 40px;
    background: #4644b1;
    color: aliceblue;
    font-size: 16px;
    letter-spacing: 1px;
    border: none;
    border-radius: 0 4px 4px 0;
}


.nav-login-logout{
    padding: 17px 40px;
    background: #b2b2b2;
    border: none;
    font-size: 16px;
}