.footer-container {
  /* border-top: 1px solid rgb(55, 53, 53); */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    background: #f1f7f8;
}

.footer-main {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: auto;
  padding: 20px;
}

.footer-logo-middle {
  display: flex;
}

.footer-logo-middle p {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding-left: 20px;
  opacity: 0.4;
  color: #000000;
}

.footer-logo-middle img {
  width: 110px;
  height: 40px;
}

.footer-img-icon {
  display: flex;
  justify-content: space-evenly;
}
.footer-img-icon img {
  width: 35px;
  height: 35px;
  margin: 5px;
}

.copyright-footer p {
  opacity: 0.4;
  font-size: 12px;
  line-height: 1.5;
  color: #000000;
  margin-top: 10px;
  margin-left: 20px;
  text-align: justify;
}


@media screen and (max-width: 850px) {

  .footer-main {
    display: flex;
    justify-content: space-between;
    width: 95%;
    margin: auto;
    padding: 5px;
}

}

@media screen and (max-width: 450px) {
  .footer-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 90%;
    margin: auto;
    padding: 5px;
}

}

