.dashboard-container {
  /* width: 80%; */
  /* height: 100vh; */
  background: #f8f8fb;
  
}
.dashboard-top-content {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: auto ;
  /* border: 1px solid red; */
  width: 90%;
  padding-top: 20px;
  
}
.dashboard-top-content h4{
  font-size: 28px;
  

}


.dashboard-top-content button{
    padding: 15px 35px;
    letter-spacing: 1px;
    border-radius: 4px;
    border: none;
    color: white;
    background: #4644b1;
    font-size: 16px;
    height: fit-content;
}


.wallet-amountpayable-component{
  display: flex;
  justify-content: space-evenly;
  width: 80%;
  margin-top: 7%;
}